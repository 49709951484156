import { memberModifier } from '@gonfalon/members';
import { getQueryClient } from '@gonfalon/react-query-client';
import { isRESTAPIError, JSONPatch, memberQuery, usePatchMember, usePatchTeam } from '@gonfalon/rest-api';
import { makeCustomRoleInstructions, teamModifier } from '@gonfalon/teams';
import {
  Button,
  ButtonGroup,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
  ProgressBar,
  SnackbarQueue,
  ToastQueue,
} from '@launchpad-ui/components';

import { getRoleAttributesFromPolicy } from '../getRoleAttributesFromPolicy';
import { CustomRole, Member, Team } from '../internal/types';

type BaseProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  createJsonPatch: <T>(
    prev: T,
    next: T,
  ) =>
    | JSONPatch
    | {
        comment: string;
        patch: JSONPatch;
      };
  forResource: 'member' | 'team';
  member?: Member;
  team?: Team;
  role: CustomRole;
};

type RemoveAccessToMemberProps = BaseProps & {
  forResource: 'member';
  member: Member;
};

type RemoveAccessToTeamProps = BaseProps & {
  forResource: 'team';
  team: Team;
};

export type RemoveAccessModalProps = RemoveAccessToMemberProps | RemoveAccessToTeamProps;

export const RemoveAccessModal = ({
  isOpen,
  setIsOpen,
  createJsonPatch,
  forResource,
  member,
  team,
  role,
}: RemoveAccessModalProps) => {
  const { mutate: updateMember, isPending: isUpdatingMember } = usePatchMember();
  const { mutate: updateTeam, isPending: isUpdatingTeam } = usePatchTeam();

  const roleAttributes = getRoleAttributesFromPolicy(role.policy);

  const isForMember = forResource === 'member';
  const isPending = isUpdatingMember || isUpdatingTeam;

  const getUpdatedRoleAttributes = (roleAttrs: { [key: string]: string[] | undefined } = {}) => {
    if (!roleAttrs) {
      return;
    }

    const updatedRoleAttributes = { ...roleAttrs };
    roleAttributes.forEach(({ attribute: attributeKey }) => {
      delete updatedRoleAttributes[attributeKey];
    });
    return updatedRoleAttributes;
  };

  const handleRemoveAccess = () => {
    if (isForMember) {
      const updatedRoleAttributes = getUpdatedRoleAttributes(member.roleAttributes);

      const updatedMember = memberModifier(member)
        .removeCustomRole(role._id)
        .replaceRoleAttributes(updatedRoleAttributes);

      const patch = createJsonPatch(member, updatedMember) as JSONPatch;
      updateMember(
        { body: patch, id: member._id },
        {
          onSuccess: async () => {
            const queryClient = getQueryClient();
            await queryClient.invalidateQueries(memberQuery({ id: member._id, query: { expand: 'roleAttributes' } }));
            ToastQueue.success('Role successfully removed from member');
            setIsOpen(false);
          },
          onError: (err: Error) => {
            SnackbarQueue.error({
              description: isRESTAPIError(err) ? err?.message : 'Failed to remove role from member. Try again later.',
            });
          },
        },
      );
      return;
    }

    if (team && team.key) {
      const teamKey = team.key;
      const updatedTeam = teamModifier(team)
        .removeCustomRole(role.key)
        .replaceRoleAttributes(getUpdatedRoleAttributes(team.roleAttributes));

      const instructions = makeCustomRoleInstructions(team, updatedTeam);
      updateTeam(
        {
          body: { instructions },
          teamKey,
        },
        {
          onSuccess: () => {
            ToastQueue.success('Role successfully removed from team');
            setIsOpen(false);
          },
          onError: (err: Error) => {
            SnackbarQueue.error({
              description: isRESTAPIError(err) ? err?.message : 'Failed to remove role from team. Try again later.',
            });
          },
        },
      );
    }
  };
  return (
    <ModalOverlay isOpen={isOpen} onOpenChange={setIsOpen}>
      <Modal>
        <Dialog>
          <div slot="header">
            <Heading slot="title">Remove access</Heading>
            <p>
              This action will remove access to this role. This may affect other roles for this member if this role has
              assigned attribute values and other roles are using the same values. Are you sure you want to remove
              access?
            </p>
          </div>
          <div slot="footer">
            <ButtonGroup>
              <Button onPress={() => setIsOpen(false)}>Cancel</Button>
              <Button variant="destructive" onPress={handleRemoveAccess} isDisabled={isPending}>
                {isPending ? <ProgressBar isIndeterminate size="small" /> : 'Remove access'}
              </Button>
            </ButtonGroup>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
