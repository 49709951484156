import { updateIssue } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { monitoringIssuesList } from './internal/queries';

type UpdateIssueInput = Parameters<typeof updateIssue>[0];

export const useUpdateIssue = createMutationHook(
  (input: UpdateIssueInput) => updateIssue({ ...input, apiVersion: 'beta' }),
  {
    onSuccess: async (_data, { projectKey }) => {
      const queryClient = getQueryClient();
      await queryClient.invalidateQueries({ queryKey: monitoringIssuesList.partialQueryKey({ projectKey }) });
    },
  },
);
