import { getIssues } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { createQueryOptions } from './internal/createQueryOptions';
import { monitoringIssuesList } from './internal/queries';

export const fetchMonitoringIssues = createQueryFetcher(
  (
    { projectKey, sortBy }: { projectKey: string; sortBy?: 'last_seen_error' | 'none' | undefined } = {
      projectKey: '',
      sortBy: 'last_seen_error',
    },
  ) => monitoringIssuesList({ projectKey, query: { sortBy } }),
);

export const getIssuesQuery = createQueryOptions(getIssues);
