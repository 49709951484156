import type { schemas } from '@gonfalon/openapi';

export type Access = schemas['Access'];

type AccessAllowedReason = schemas['AccessAllowedReason'];
type AccessDeniedReason = schemas['AccessDeniedReason'];
export type AccessReason = AccessAllowedReason | AccessDeniedReason;

export type AccessAllowed = schemas['AccessAllowedRep'];
export type AccessDenied = schemas['AccessDenied'];

export type AccessCheck =
  | Readonly<{ isAllowed: true }>
  | Readonly<{
      isAllowed: false;
      appliedRoleName?: string;
      customMessage?: string;
      reason?: AccessReason;
    }>;

export type EnvironmentChangeConfirmation = {
  isConfirmationRequired: boolean;
  isCommentRequired: boolean;
  isVerificationRequired: boolean;
};

export type CustomRole = schemas['CustomRole'];
export enum RoleResourceCategory {
  PROJECT = 'project',
  ORGANIZATION = 'organization',
  ANY = 'any',
}
export type Statement = schemas['Statement'];
export type Member = schemas['Member'];
export type Team = schemas['Team'];

export enum RoleName {
  NO_ACCESS = 'no_access',
  READER = 'reader',
  WRITER = 'writer',
  OWNER = 'owner',
  ADMIN = 'admin',
  CUSTOM = 'custom',
  INLINE = 'inline',
}
